import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { CompetitionsService } from "../services";
import firebase from "../firebase";
import { useCountry } from "./CountryContext";

type ContextState = {
  competitions: Competition[];
  loading: boolean;
  error: Error | null;
  createCompetition: (
    data: Competition
  ) => Promise<firebase.firestore.DocumentReference | null>;
  updateCompetition: (
    id: Competition["id"],
    data: Competition
  ) => Promise<void>;
  deleteCompetition: (id: Competition["id"]) => Promise<void>;
  uploadCompetitionImage: (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => Promise<string>;
  updateSortOrder: (competitions: Competition[]) => Promise<void>;
  getAnswersForCompetition: (
    competitionId: CompetitionAnswers["competitionId"],
    sortOrder?: "asc" | "desc"
  ) => Promise<CompetitionAnswers[]>;
  getById: (id: Competition["id"]) => Competition | null;
};

const CompetitionsContext = createContext<ContextState>({
  competitions: [],
  loading: false,
  error: null,
  createCompetition: async () => null,
  updateCompetition: async () => null,
  deleteCompetition: async () => null,
  uploadCompetitionImage: async () => null,
  updateSortOrder: async () => null,
  getAnswersForCompetition: async () => null,
  getById: () => null,
});

export const CompetitionsProvider = ({ ...rest }) => {
  const [competitions, setCompetitions] = useState<Competition[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const competitionsService = useMemo(
    () => new CompetitionsService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  useEffect(() => {
    setLoading(true);

    const unsub = competitionsService.subscribe((_error, _competitions) => {
      setCompetitions(_competitions);
      setError(_error);
      setLoading(false);
    });

    return unsub;
  }, [competitionsService]);

  const createCompetition = useCallback(
    async (data: Competition) => await competitionsService.create(data),
    [competitionsService]
  );

  const updateCompetition = useCallback(
    async (id: Competition["id"], data: Competition) =>
      await competitionsService.update(id, data),
    [competitionsService]
  );

  const deleteCompetition = useCallback(
    async (id: Competition["id"]) => await competitionsService.delete(id),
    [competitionsService]
  );

  const uploadCompetitionImage = useCallback(
    async (file: Blob | ArrayBuffer, fileName: string) =>
      await competitionsService.uploadCompetitionImage(file, fileName),
    [competitionsService]
  );

  const updateSortOrder = useCallback(
    async (competitions: Competition[]) =>
      await competitionsService.updateSortOrder(competitions),
    [competitionsService]
  );

  const getAnswersForCompetition = useCallback(
    async (competitionId: string, sortOrder: "asc" | "desc" = "desc") =>
      await competitionsService.getAnswersForCompetition(
        competitionId,
        sortOrder
      ),
    [competitionsService]
  );

  const getById = useCallback(
    (id: Competition["id"]) => {
      const competition = competitions.find(c => c.id === id);
      return competition || null;
    },
    [competitions]
  );

  const value = useMemo(
    () => ({
      competitions,
      loading,
      error,
      createCompetition,
      updateCompetition,
      deleteCompetition,
      uploadCompetitionImage,
      updateSortOrder,
      getAnswersForCompetition,
      getById,
    }),
    [
      competitions,
      loading,
      error,
      createCompetition,
      updateCompetition,
      deleteCompetition,
      uploadCompetitionImage,
      updateSortOrder,
      getAnswersForCompetition,
      getById,
    ]
  );

  return <CompetitionsContext.Provider value={value} {...rest} />;
};

export const useCompetitions = () => {
  const context = React.useContext(CompetitionsContext);
  if (context === undefined) {
    throw new Error(
      "useCompetitions must be used within an competitionsProvider"
    );
  }
  return context;
};

export const useCompetition = (competitionId: Competition["id"]) => {
  const [competition, setCompetition] = useState<Competition | null>(null);
  const [loading, setLoading] = useState(true);

  const { getById, loading: loadingCompetitions } = useCompetitions();

  useEffect(() => {
    if (loadingCompetitions) {
      return;
    }

    if (competitionId) {
      setCompetition(getById(competitionId));
    }
    setLoading(false);
  }, [loadingCompetitions, getById, competitionId]);

  return { competition, loading };
};
