import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { ConfirmationModal, FormEdit, FormEditType } from "../../components";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Divider, Form, Row, Typography, Alert } from "antd";
import {
  formatDateStringToTimeStamp,
  formatRemoteNotificationMessage,
  formValidationError,
  generateFormError,
  getUserSegments,
  range,
  // range,
} from "../../utils/commonUtils";
import {
  AspectRatio,
  getRemoteNotificationTopics,
  isEqualArrays,
  NUM_OF_MONTHS,
  NUM_OF_WEEKS,
  RemoteNotificationRecordType,
  UserSegmantType,
} from "../../utils";
import { useRemoteNotifications } from "../../context/RemoteNotificationContext";
import {
  BJInputFormItem,
  BJNotification,
  BJSelectFormItem,
  NotificationType,
} from "../../components/theme";
import { DropAndCrop } from "../../components/DropAndCrop";
import BJButton, { ButtonTypes } from "../../components/theme/atoms/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchService } from "../../services/SearchService";
import {
  useAuth,
  useBlogCategories,
  useCompetitions,
  useCountry,
} from "../../context";
import styled from "styled-components";
import { remoteNotificationSchema } from "../../schemas/remoteNotificationSchema";
import { BJSlider } from "../../components/theme/molecules/BJSlider";
import moment from "moment";
import { BJDeeplinkFormInput } from "../../components/theme/molecules/formItems/BJDeeplinkFormInput";
import { BJFormDatePicker } from "../../components/theme/molecules/formItems/BJFormDatePicker";
import * as yup from "yup";
import { RemoteNotificationMessages, commonErrors } from "../../language";

type FormValues = {
  fcmToken: string;
  imageUrl: string;
  notificationName: string;
  topic: string;
  status: string;
  contentId: string;
  deepLink: string;
  userSegment: UserSegmantType;
  city: string;
  category: string;
  pregnancyWeek: string;
  competitionId: string;
  scheduldedAt: any; //moment.Moment;
  translations: {
    [locale: string]: {
      title: string;
      body: string;
    };
  };
};

const RemoteNotificationPage = () => {
  const { primaryLocale, currentCountry } = useCountry();

  const { competitions } = useCompetitions();
  const transformedCompetitions = useCallback(
    locale =>
      competitions.map(comp => {
        return {
          key: comp.id,
          value: comp.id,
          display: comp?.translations?.[locale?.key]
            ? comp?.translations?.[locale?.key]?.title
            : comp?.translations?.[primaryLocale?.key]?.title,
          disabledOption: !comp.isActive,
        };
      }),
    [competitions, primaryLocale]
  );

  const schema = yup.object().shape({
    ...remoteNotificationSchema,
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          title: yup
            .string()
            .required(
              `Title (${String(item.key).toUpperCase()}): ${
                commonErrors.requiredError2
              }`
            )
            .max(65, RemoteNotificationMessages.titleLengthError),
          body: yup
            .string()
            .required(
              `Body (${String(item.key).toUpperCase()}): ${
                commonErrors.requiredError2
              }`
            )
            .max(240, RemoteNotificationMessages.titleLengthError),
        });
        return acc;
      }, {} as any)
    ),
  });

  const {
    formState,
    reset,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
    control,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      translations: currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = {
          title: "",
          body: "",
        };
        return acc;
      }, {} as any),
    },
    resolver: yupResolver(schema),
  });

  const { loading, getById } = useRemoteNotifications();
  const [loadingOnSendMessage, setLoadingOnSendMessage] = useState(false);
  const [enableTestNotification, setEnableTestNotification] = useState(false);
  const [enablePublish, setEnablePublish] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [monthRange, setMonthRange] = useState<[number, number]>([0, 12]);
  const [weekRange, setWeekRange] = useState<[number, number]>([
    35,
    NUM_OF_WEEKS,
  ]);
  const [cities, setCities] = useState<string[]>([]);
  const { id } = useParams<string>();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { blogCategories } = useBlogCategories();
  const rearrangedId = state?.duplicate ? state.id : id;
  const notificationById = getById(rearrangedId);
  const { user } = useAuth();
  const {
    saveNotificationInfo,
    onRemoveScheduled,
    sendRemoteNotifiction,
    uploadRemoteNotificationImage,
  } = useRemoteNotifications();

  const searchService = useMemo(
    () => new SearchService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  const remoteNotification = useMemo(
    () =>
      state?.duplicate
        ? {
            ...notificationById,
            status: RemoteNotificationRecordType.duplicatedDraft,
            scheduledAt: null,
          }
        : notificationById,
    [state?.duplicate, notificationById]
  );

  const { fcmToken, topic, userSegment, scheduldedAt } = watch();

  useEffect(() => {
    if (loading || remoteNotification === null) {
      return;
    }
    const scheduledAt = remoteNotification?.scheduledAt
      ? moment(remoteNotification?.scheduledAt)
      : null;
    reset({
      translations: remoteNotification?.notification?.translations ?? {},
      deepLink: remoteNotification?.data?.deepLink,
      topic: remoteNotification?.topic,
      notificationName: remoteNotification?.notificationName,
      imageUrl: remoteNotification?.imageUrl,
      status: remoteNotification?.status,
      userSegment: remoteNotification?.userSegment,
      city: remoteNotification?.extra?.city,
      category: remoteNotification?.extra?.blogCategory,
      scheduldedAt: scheduledAt ?? null,
      fcmToken: remoteNotification?.to,
      competitionId: remoteNotification?.extra?.competitionId ?? null,
    });

    setWeekRange(x => remoteNotification?.extra?.weeksNumberRange ?? x);
    setMonthRange(x => remoteNotification?.extra?.monthsNumberRange ?? x);
    setDisabled(
      (remoteNotification?.status === RemoteNotificationRecordType.pending ||
        remoteNotification?.status ===
          RemoteNotificationRecordType.scheduleDeleted ||
        remoteNotification?.status ===
          RemoteNotificationRecordType.scheduleDeletePending ||
        remoteNotification?.status ===
          RemoteNotificationRecordType.schedulePending ||
        remoteNotification?.status === RemoteNotificationRecordType.scheduled ||
        remoteNotification?.status === RemoteNotificationRecordType.completed ||
        remoteNotification?.status === RemoteNotificationRecordType.error) &&
        !!remoteNotification?.id
    );
  }, [remoteNotification, loading, reset]);

  useEffect(() => {
    setEnableTestNotification(!!fcmToken);
  }, [fcmToken]);

  useEffect(() => {
    searchService
      .getData()
      .then(data => {
        setCities(data.municipalities.names);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setEnablePublish((!!topic || !!userSegment) && !disabled);
  }, [disabled, topic, userSegment]);

  const onSubmit = async () => {
    const notificationInfo = generateNotificationObj(primaryLocale?.key);
    const id = await saveNotificationInfo(
      {
        ...notificationInfo,
      },
      RemoteNotificationRecordType.draft
    );
    return navigate(`../${id}`);
  };

  const handleImageUrl = (url: string | null) => {
    setValue("imageUrl", url, { shouldDirty: true });
  };

  const copyToNotificationDeepLink = (link: string) => {
    setValue("deepLink", link);
  };

  const generateNotificationObj = (locale: string) => {
    const formValues = getValues();
    //created temp value for primary trans value
    const temp = formValues.translations[primaryLocale.key];
    //if topic is selected, set only the primary language value
    const trans: NotificationInfo["notification"]["translations"] =
      formValues.topic !== undefined
        ? { [primaryLocale.key]: temp }
        : formValues?.translations ?? {};

    const notificationInfo: NotificationInfo = {
      id:
        remoteNotification?.status ===
        RemoteNotificationRecordType.duplicatedDraft
          ? null
          : remoteNotification?.id,
      topic: formValues.topic,
      userSegment: formValues.userSegment,
      selectedPregnancyWeek: formValues.pregnancyWeek,
      to: formValues.fcmToken,
      updatedBy: user.id,
      triggerTime: formValues.scheduldedAt
        ? formatDateStringToTimeStamp(formValues.scheduldedAt, false)
        : new Date(),
      scheduledAt: formValues.scheduldedAt
        ? formatDateStringToTimeStamp(formValues.scheduldedAt, false)
        : null,
      data: {
        deepLink: formValues.deepLink ? formValues.deepLink : "",
        id: remoteNotification?.id,
      },
      extra: {
        city:
          userSegment === UserSegmantType.usersWithSelectedTown
            ? formValues.city
            : undefined,
        blogCategory:
          userSegment === UserSegmantType.usersWithCategorySpecified
            ? formValues.category
            : undefined,
        weeksNumberRange:
          userSegment === UserSegmantType.pregnanciesInOrAboveWeekNumber
            ? weekRange
            : undefined,
        monthsNumberRange:
          userSegment === UserSegmantType.childrenInMonthsBetween
            ? monthRange
            : undefined,
        competitionId:
          userSegment === UserSegmantType.usersNotParticipatedInCompetition
            ? formValues?.competitionId
            : undefined,
      },
      notificationName: formValues.notificationName,

      notification: {
        translations: trans,
        imageUrl: formValues.imageUrl,
      },
    };
    return notificationInfo;
  };

  const handleOnRemoveScheduled = async () => {
    onRemoveScheduled(id);
  };

  const publishNotification = async () => {
    setShowConfirmationModal(false);
    const valid = await trigger();
    if (valid) {
      setLoadingOnSendMessage(true);

      const notificationInfo = generateNotificationObj(primaryLocale?.key);
      await saveNotificationInfo(
        notificationInfo,
        notificationInfo.scheduledAt
          ? RemoteNotificationRecordType.schedulePending
          : RemoteNotificationRecordType.pending
      );
      try {
        // No need to await here as it takes time to process the notification
        BJNotification({
          type: NotificationType.Success,
          message: "Notification run was successfully requested",
          description: `Notification state will be updated when the process is completed`,
        });
        return navigate(`../`);
      } catch (ex) {
        console.log(ex);
        const serverErrorMessage = generateFormError(ex);
        BJNotification({
          type: NotificationType.Error,
          message: "Notification error",
          description: serverErrorMessage,
        });
        throw ex;
      } finally {
        setLoadingOnSendMessage(false);
      }
    } else {
      BJNotification({
        type: NotificationType.Error,
        message: " Error",
        description: "Fix the validation errors and try again!",
      });
    }
  };

  const sendTestNotification = async () => {
    const valid = await trigger();
    if (valid) {
      setLoadingOnSendMessage(true);
      const notificationInfo = generateNotificationObj(primaryLocale?.key);
      try {
        await sendRemoteNotifiction(notificationInfo);
        BJNotification({
          type: NotificationType.Success,
          message: "Sent",
          description: `Sent test notification`,
        });
      } catch (ex) {
        console.log(ex);
        const serverErrorMessage = generateFormError(ex);
        BJNotification({
          type: NotificationType.Error,
          message: "Test notification error",
          description: serverErrorMessage,
        });
        throw ex;
      } finally {
        setLoadingOnSendMessage(false);
      }
    }
  };

  const disabledDateTime = (date: moment.Moment) => {
    if (date && !date.isSame(moment(), "days")) {
      return undefined;
    }

    const currentHour = moment().hours();
    const scheduldedAtHour = moment(scheduldedAt).hours();

    const currentMinute = moment().minutes();

    if (scheduldedAtHour === currentHour) {
      return {
        disabledHours: () => range(0, 24, 1).splice(0, currentHour),
        disabledMinutes: () => range(0, currentMinute, 1),
      };
    }

    return {
      disabledHours: () => range(0, 24, 1).splice(0, currentHour),
    };
  };

  const additionalButtons = () => {
    return [
      <BJButton
        key="testMessage"
        onClick={sendTestNotification}
        buttonType={ButtonTypes.Save}
        disabled={!enableTestNotification}
        loading={false}
        size="large"
        htmlType="button"
      >
        Send test message
      </BJButton>,
      <BJButton
        key={"publish"}
        onClick={() => setShowConfirmationModal(true)}
        buttonType={ButtonTypes.Save}
        disabled={!enablePublish}
        loading={false}
        size="large"
        htmlType="button"
      >
        Schedule
      </BJButton>,
    ];
  };

  const isDirty =
    !!Object.keys(formState.dirtyFields).length ||
    !isEqualArrays(
      remoteNotification?.extra?.monthsNumberRange ?? [],
      monthRange
    ) ||
    !isEqualArrays(
      remoteNotification?.extra?.weeksNumberRange ?? [],
      weekRange
    );

  return (
    <>
      <FormEdit
        editType={id ? FormEditType.EDIT : FormEditType.ADD}
        onRemove={
          remoteNotification?.status ===
            RemoteNotificationRecordType.scheduled && handleOnRemoveScheduled
        }
        backRoutePath={`../`}
        textToOverrideSave="Save as draft"
        hasValidationErrors={Object.keys(errors).length !== 0}
        enableSave={
          isDirty &&
          (!remoteNotification?.status ||
            remoteNotification?.status === RemoteNotificationRecordType.draft ||
            remoteNotification?.status ===
              RemoteNotificationRecordType.duplicatedDraft)
        }
        title={
          remoteNotification?.id
            ? remoteNotification?.notification?.translations?.[
                primaryLocale?.key
              ]?.title ?? ""
            : "New Notification"
        }
        loading={loading || loadingOnSendMessage}
        onSubmit={handleSubmit(onSubmit, formValidationError)}
        recordIdentifier={
          remoteNotification?.notification?.translations?.[primaryLocale?.key]
            ?.title ?? ""
        }
        additionalButtons={additionalButtons()}
        deleteMessageTitle="Notification deletion was successfully requested"
        deleteMessage="Notification state will be updated when the process is completed"
        deleteConfirmationMessage="This will remove the scheduled notification, are you sure?"
        deleteButtonText="Delete schedule"
        localeSupported
        errors={errors as any}
      >
        {locale => (
          <Row gutter={24}>
            <Col span={24}>
              <NotificationResultMessage
                remoteNotification={remoteNotification}
              />
            </Col>
            <Col span={12}>
              <BJInputFormItem
                key={`message-title.${locale?.key}`}
                disabled={disabled}
                autoFocus
                control={control}
                error={!!errors.translations?.[locale?.key]?.title}
                label={`Notification title (${locale?.label})`}
                message={errors.translations?.[locale?.key]?.title?.message}
                required={true}
                fieldName={`translations.${locale?.key}.title`}
                showInfo
                extra={
                  "Maximum character length for title Android - 65 / iOS - 178"
                }
                // suffix={notificationTitle?.length} temporarly disabled due to out of focus issue
              />
              <BJInputFormItem
                key={`message-body.${locale?.key}`}
                autoComplete={"off"}
                disabled={disabled}
                control={control}
                error={!!errors.translations?.[locale?.key]?.body}
                label={`Notification text (${locale?.label})`}
                message={errors.translations?.[locale?.key]?.body?.message}
                required={true}
                fieldName={`translations.${locale?.key}.body`}
                showInfo
                extra={
                  "Maximum character length for Body Android - 240 / iOS - 178"
                }
                //suffix={notificationBody?.length} temporarly disabled due to out of focus issue
              />
              <Form.Item
                label="Image"
                validateStatus={errors?.imageUrl?.message && "error"}
                {...(errors?.imageUrl?.message
                  ? {
                      help: (
                        <Typography.Paragraph type="danger">
                          {errors?.imageUrl?.message}
                        </Typography.Paragraph>
                      ),
                    }
                  : undefined)}
              >
                <Controller
                  control={control}
                  name="imageUrl"
                  render={() => (
                    <DropAndCrop
                      disabled={disabled}
                      title="Notification image (optional) "
                      setUploadUrl={handleImageUrl}
                      uploadImage={uploadRemoteNotificationImage}
                      initialUrl={remoteNotification?.imageUrl}
                      lockedRatio={AspectRatio.OneToOne}
                    />
                  )}
                />
              </Form.Item>
              <BJDeeplinkFormInput
                control={control}
                error={!!errors.deepLink}
                label={"Deep link"}
                message={errors.deepLink?.message}
                required
                fieldName={"deepLink"}
                title="Remote notifications"
                copyToNotificationDeepLink={copyToNotificationDeepLink}
              />
            </Col>
            <Col span={12}>
              <BJInputFormItem
                disabled={disabled}
                control={control}
                error={!!errors.fcmToken}
                label={"Fcm token"}
                message={errors.fcmToken?.message}
                showInfo
                extra={
                  "If fcm token is added, notification will be sent to the given device only ( will ignore user segments and topics )"
                }
                fieldName={"fcmToken"}
              />
              {renderDivider(userSegment, "User segment")}
              {!fcmToken && (
                <BJSelectFormItem
                  disabled={disabled}
                  size="large"
                  control={control}
                  error={!!errors.topic}
                  label={"User segment"}
                  showInfo
                  extra="Message will be published all users in selected segment"
                  message={errors.userSegment?.message}
                  optionsList={getUserSegments()}
                  fieldName={"userSegment"}
                  includeEmpty
                />
              )}
              {!fcmToken &&
                userSegment ==
                  UserSegmantType.usersNotParticipatedInCompetition && (
                  <BJSelectFormItem
                    disabled={disabled}
                    size="large"
                    control={control}
                    error={!!errors.competitionId}
                    label={"Competition"}
                    showInfo
                    extra="Message will be published all users who have not participated in this competition"
                    message={errors.competitionId?.message}
                    optionsList={transformedCompetitions(locale)}
                    fieldName={"competitionId"}
                    includeEmpty
                  />
                )}
              {userSegment === UserSegmantType.usersWithSelectedTown &&
                !fcmToken && (
                  <BJSelectFormItem
                    disabled={disabled}
                    size="large"
                    control={control}
                    error={!!errors.city}
                    label={"City"}
                    showInfo
                    extra="Select city of users targeting"
                    message={errors.city?.message}
                    optionsList={cities.map(x => ({
                      key: x,
                      value: x,
                      display: x,
                    }))}
                    fieldName={"city"}
                  />
                )}
              {userSegment === UserSegmantType.usersWithCategorySpecified &&
                !fcmToken && (
                  <BJSelectFormItem
                    disabled={disabled}
                    size="large"
                    control={control}
                    error={!!errors.category}
                    label={"Categories"}
                    showInfo
                    extra="Select category"
                    message={errors.category?.message}
                    optionsList={blogCategories.map(x => ({
                      key: x.id,
                      value: x.id,
                      display: x.translations.en ?? "",
                    }))}
                    fieldName={"category"}
                  />
                )}
              {userSegment === UserSegmantType.pregnanciesInOrAboveWeekNumber &&
                !fcmToken && (
                  <BJSlider
                    selectedRange={weekRange}
                    disabled={disabled}
                    onChange={(value: [number, number]) => {
                      setWeekRange(value);
                    }}
                    range
                    value={weekRange}
                    step={1}
                    defaultValue={[35, NUM_OF_WEEKS]}
                    max={NUM_OF_WEEKS}
                    min={1}
                    showInfo
                    extra={
                      "If selected range from 1,3, users who`s pregnancy week is 1,2,3 will receive notifications"
                    }
                    error={false}
                    message={""}
                    label={"select range"}
                  />
                )}
              {userSegment === UserSegmantType.childrenInMonthsBetween &&
                !fcmToken && (
                  <BJSlider
                    disabled={disabled}
                    range
                    selectedRange={monthRange}
                    showInfo
                    extra={
                      "If selected range from 0,3, users who`s child is in month  0,1,2,3 will receive notifications"
                    }
                    onChange={(value: [number, number]) => {
                      setMonthRange(value);
                    }}
                    step={1}
                    defaultValue={[1, 12]}
                    max={NUM_OF_MONTHS}
                    min={0}
                    value={monthRange}
                    error={false}
                    message={""}
                    label={"select range"}
                  />
                )}
              {renderDivider(userSegment)}
              {!fcmToken && (
                <BJSelectFormItem
                  disabled={disabled}
                  size="large"
                  control={control}
                  error={!!errors.topic}
                  label={"Topic"}
                  showInfo
                  extra="Message will be published to selected topic subscribed devices, user segments has higher priority"
                  message={errors.topic?.message}
                  optionsList={getRemoteNotificationTopics()}
                  fieldName={"topic"}
                  includeEmpty
                />
              )}
              <BJFormDatePicker
                label="Scheduled at"
                required={true}
                message={errors.scheduldedAt?.message}
                error={!!errors.scheduldedAt}
                fieldName={"scheduldedAt"}
                control={control}
                disabledDate={d =>
                  moment(moment(d).format("YYYY-MM-DD")).isSameOrBefore(
                    moment(moment().add(-1, "days").format("YYYY-MM-DD"))
                  )
                }
                disabledTime={disabledDateTime}
                disabled={disabled}
                format={"YYYY-MM-DD HH:mm"}
                defaultValue={null}
                showTime
                size={"large"}
              />
            </Col>
          </Row>
        )}
      </FormEdit>
      <ConfirmationModal
        show={showConfirmationModal}
        text={
          "This notification will be recieved by multiple users, Are you sure?"
        }
        onHide={() => setShowConfirmationModal(false)}
        onConfirm={publishNotification}
      />
    </>
  );
};

export const renderDivider = (
  userSegment?: UserSegmantType,
  segName?: string
) => {
  {
    return (
      userSegment &&
      (userSegment === UserSegmantType.usersWithSelectedTown ||
        userSegment === UserSegmantType.pregnanciesInOrAboveWeekNumber ||
        userSegment === UserSegmantType.usersWithCategorySpecified) && (
        <Divider orientation="left">{segName && segName}</Divider>
      )
    );
  }
};

const NotificationResultMessage = ({
  remoteNotification,
}: {
  remoteNotification: NotificationInfo;
}) => {
  const composeNotification = useMemo(
    () => formatRemoteNotificationMessage(remoteNotification?.status),
    [remoteNotification]
  );

  if (
    !remoteNotification ||
    !remoteNotification?.id ||
    remoteNotification?.status === RemoteNotificationRecordType.duplicatedDraft
  )
    return <></>;
  return (
    <StyledDiv>
      <Alert {...composeNotification} showIcon />
    </StyledDiv>
  );
};

export default RemoteNotificationPage;

const StyledDiv = styled.div`
  margin-bottom: 1rem;
`;
