import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Modal } from "antd";
import { BsPlusCircleFill } from "react-icons/bs";

import { formValidationError, generateServerError } from "../../utils";
import { commonErrors } from "../../language";
import { MissionsService } from "../../services";

import { FormModal } from "../../components";
import {
  BJColumnType,
  BJInputFormItem,
  BJNotification,
  Sorter,
  theme,
} from "../../components/theme";
import BJList from "../../components/theme/components/BJList";
import BJButton, { ButtonTypes } from "../../components/theme/atoms/Button";

interface SubledgerModalProps {
  show: boolean;
  onHide: () => void;
}

interface SubledgerItem {
  title: string;
}

type SubledgerItems = Array<SubledgerItem>;

const columns: BJColumnType<SubledgerItem>[] = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    width: 1,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
];

const schema = yup.object().shape({
  title: yup.string().required(`Title: ${commonErrors.requiredError}`),
});

export const SubledgerModal = ({ show, onHide }: SubledgerModalProps) => {
  const [loading, setLoading] = useState(true);
  const [subledgerOptions, setSubledgerOptions] = useState<SubledgerItems>([]);
  const [showForm, setShowForm] = useState(false);
  const [editSubledger, setEditSubledger] = useState("");

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm<{ title: string }>({ resolver: yupResolver(schema) });

  const onRemoveSubledger = useCallback(async () => {
    try {
      if (editSubledger) {
        await MissionsService.deleteSubledger(editSubledger);
        setEditSubledger(undefined);
      }
    } catch (error) {
      const serverErrorMessage = generateServerError(error);

      BJNotification({
        type: "error",
        message: "Failed",
        description: serverErrorMessage,
      });
    }
  }, [editSubledger]);

  const showFormModal = useCallback(() => {
    setShowForm(true);
  }, []);

  const hideFormModal = useCallback(() => {
    reset({ title: "" });
    setEditSubledger("");
    setShowForm(false);
  }, [reset]);

  const onClickSubledger = useCallback(
    (data: SubledgerItem) => {
      return {
        onClick() {
          setEditSubledger(data.title);
          setValue("title", data.title);
          setShowForm(true);
        },
      };
    },
    [setValue]
  );

  const onSubmit = useCallback(
    async (data: { title: string }) => {
      try {
        if (data.title) {
          if (editSubledger) {
            await MissionsService.updateSubledger({
              old: editSubledger,
              new: data.title,
            });
          } else {
            await MissionsService.createSubledger(data.title);
          }
        }
      } catch (error) {
        const serverErrorMessage = generateServerError(error);

        BJNotification({
          type: "error",
          message: "Failed",
          description: serverErrorMessage,
        });
      }
    },
    [editSubledger]
  );

  useEffect(() => {
    if (!showForm || !editSubledger) {
      const initSubledgers = async () => {
        const subledgers = await MissionsService.getSubledgerOptions();

        setSubledgerOptions(
          subledgers.map(subledger => ({ title: subledger }))
        );
        setLoading(false);
      };

      initSubledgers();
    }
  }, [showForm, editSubledger]);

  const isEdit = Boolean(editSubledger);
  const modalTitle = isEdit ? "Edit subledger" : "Add new subledger";

  return (
    <>
      <Modal
        visible={show}
        width={window.screen.width / 2}
        centered
        title="Subledgers"
        onCancel={onHide}
        destroyOnClose
        footer=""
      >
        <BJButton
          buttonType={ButtonTypes.Add}
          icon={<BsPlusCircleFill size={"1rem"} color={theme.button.primary} />}
          size="large"
          onClick={showFormModal}
          style={{ marginBottom: 12 }}
        >
          New Subledger
        </BJButton>
        <BJList
          loading={loading}
          OriginalList={subledgerOptions}
          columns={columns}
          hidePrefix
          recordCountSuffix="subledgers"
          onClickRow={onClickSubledger}
        />
      </Modal>
      <FormModal
        formId="subledgers"
        onHide={hideFormModal}
        enableSave={Boolean(Object.keys(dirtyFields).length) || isEdit}
        show={showForm}
        size="sm"
        onDelete={onRemoveSubledger}
        enableDelete={isEdit}
        modalTitle={modalTitle}
        onSubmit={handleSubmit(onSubmit, formValidationError)}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errors={errors as any}
      >
        <BJInputFormItem
          label="Title"
          fieldName="title"
          key="title"
          control={control}
          error={!!errors?.title}
          message={errors?.title?.message}
          required
        />
      </FormModal>
    </>
  );
};
