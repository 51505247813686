import firebase from "firebase/compat/app";
import { CountryCodeService } from "./CountryCodeService";

export class SearchService extends CountryCodeService {
  constructor(locale: string) {
    super(locale);
  }

  sortOrderOnName = (a: string, b: string) => {
    const topMunicipalities = ["Stockholm", "Göteborg", "Malmö"];
    if (topMunicipalities.includes(a)) {
      return -1;
    } else if (topMunicipalities.includes(b)) {
      return 1;
    }

    return a.localeCompare(b, "en", { sensitivity: "base" });
  };

  toMunicipalities = (doc: any): string[] => {
    return doc.data().names.sort(this.sortOrderOnName);
  };

  getData = (): Promise<Search> =>
    firebase
      .firestore()
      .collection("search")
      .doc(
        this.countryCode === "se"
          ? "municipalities"
          : `municipalities_${this.countryCode}`
      )
      .get()
      .then(res => {
        return {
          municipalities: {
            names: this.toMunicipalities(res),
          },
        };
      });
}
